import React from 'react'

import logo from './assets/howtoplay.png'
import nowLive from './assets/now-live.png'
import playerBoard from './assets/player-board.png'
import bluePawn from './assets/blue-pawn.png'
import redPawn from './assets/red-pawn.png'
import './learn.css'

function Learn () {

  return (
    <>
      <section className="header-section learn-header">
        <img className="header-logo" src={logo} alt="That's Wizard"/>
        {/*<a href="https://www.kickstarter.com/projects/matthewsorrentino/thats-wizard" target="_blank">
          <img className="now-live-logo" src={nowLive} alt="Now Live!" />
        </a>*/}
      </section>

      <div className='content-bg'>
        <div className="content-border">
        <div className="section-container">

          <section className="learn-sections">

            <h2 className="header-text">Rulebook</h2>

            {/* <div className="regular-text regular-text-with-image">
            <div className='player-board-container'>
              <img className="player-board-image" src={playerBoard} alt="Player Board" />
              <img className='pawn-image blue-pawn-image' src={bluePawn} alt='Blue Pawn' />
              <img className='pawn-image red-pawn-image' src={redPawn} alt='Red Pawn' />
            </div>
            That’s Wizard! is a deck-design card game for two or four players. You will attempt to defeat your opponent with a deck of seven spell cards fueled by your Power, which represents your magical energy. In doing so, you will have to protect your Stamina, which represents your health.
            
            </div>

            <h2 className="header-text">Spells</h2>

            <div className="regular-text">
            That’s Wizard! is a deck-design card game for two or four players. You will attempt to defeat your opponent with a deck of seven spell cards fueled by your Power, which represents your magical energy. In doing so, you will have to protect your Stamina, which represents your health.

            </div> */}

            {/* <h2 className="header-text">Rulebooks</h2> */}

            <div className="regular-text">
               <a href="/That's Wizard Rulebook.pdf" target="_blank" download>That's Wizard! Rulebook</a>
            </div>

            {/* <div className="regular-text">
              <a href="/Arcana Rulebook.pdf" target="_blank" download>That's Wizard! Arcana Expansion</a>
            </div> */}

          </section>

        </div>
        </div>
      </div>

    </>
  )
}

export default Learn
